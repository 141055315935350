import { useParams } from 'react-router';
import { PROJECTS } from '../constants';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export const PersonalProject = () => {
  const { id } = useParams();

  const { description, images, link, name } =
    PROJECTS.find((project) => project.id === id) || {};

  return (
    <div className="text-white flex flex-col items-center justify-center pt-10 gap-4">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="text-xl p-4 font-semibold underline"
      >
        {name}
      </a>
      <ReactMarkdown
        className="w-full max-w-screen-md text-white prose prose-h2:text-white prose-strong:text-white prose-h3:text-white prose-a:text-blue-400 prose-code:text-gray-500"
        remarkPlugins={[remarkGfm]}
        children={description}
      />
      {images?.map((image) => (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img
            className={`w-full max-w-screen-md object-cover shadow-bot-picture-shadow rounded`}
            src={image}
            alt={`${name} bot icon`}
          />
        </a>
      ))}
    </div>
  );
};
