import React from 'react';
import { PROJECTS } from '../constants';

type ProjectLinkProps = {
  name: string;
  description: string;
  poster: string;
  images: string[];
  id: string;
};

const ProjectLink = ({
  name,
  description,
  poster,
  images,
  id,
}: ProjectLinkProps) => {
  return (
    <div
      className="flex flex-col m-6 text-white items-center justify-center"
      key={name}
    >
      <a
        href={`/personal-projects/${id}`}
        className="flex flex-col items-center justify-center"
      >
        <img
          className={`w-64 h-48 object-cover rounded shadow-bot-picture-shadow`}
          src={poster}
          alt={`${name} showcase`}
        />
        <p className="text-center mt-8 uppercase">{name}</p>
      </a>
    </div>
  );
};

export const PersonalProjects = () => (
  <div className="flex flex-row flex-wrap justify-center items-center">
    {PROJECTS.map(ProjectLink)}
  </div>
);
