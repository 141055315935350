import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'font-awesome/css/font-awesome.min.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router';
import { NavBar } from './components/NavBar';
import { Bots } from './components/Bots';
import { Bot } from './components/Bot';
import { Analytics } from './components/Analytics';
import { PersonalProjects } from './components/PersonalProjects';
import { PersonalProject } from './components/PersonalProject';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NavBar />
      <Analytics />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/bots" element={<Bots />} />
        <Route path="/bots/:id" element={<Bot />} />
        <Route path="/bots/:id" element={<Bot />} />
        <Route path="/personal-projects" element={<PersonalProjects />} />
        <Route path="/personal-projects/:id" element={<PersonalProject />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
