export const PARAGRAPHS = [
  `I'm a full-stack developer with a passion for coding, hacking, and solving problems. I started this journey in my early teenage years to hack video-games, realizing that with coding I could hack, improve and create awesome stuff. My passion quickly evolved into creating software to help my parents' business and into pursuing a degree in CS in the US, winning Hackathons and doing startups.`,
  `I'm now a seasoned hacker, full-stack and mobile developer with years of experience in several languages. My area of expertise, and the area I currently have the most fun is in Full-Stack Engineering, building complex serverless apps in Next, React, and GraphQL.`,
];

export const TLDR = `I'm a javascript master that loves building cool serverless applications in a React/Next front-end and a GQL backend. Serverless/JAMstack ❤`;

export const PARTICULAR_SKILLS = [
  {
    name: 'Languages',
    skills: [
      'Javascript',
      'Typescript',
      'GraphQL',
      'HTML',
      'CSS',
      'SCSS',
      'Ruby',
      'JAVA',
      'C/C++',
      'C#',
      'Swift',
      'PHP',
    ],
  },
  {
    name: 'Favorites',
    skills: [
      'React',
      'Next',
      'NodeJS',
      'GraphQL',
      'Serverless',
      'CI/CD',
      'Tailwind',
      'Flexbox',
      'Redux',
      'Webpack',
      'React-Native',
      'Rails',
      'Express',
      'Mongoose',
    ],
  },
  {
    name: 'Platforms',
    skills: ['Netlify', 'Amazon AWS', 'Digital Ocean', 'FireBase', 'Docker'],
  },
  {
    name: 'Databases',
    skills: ['MongoDB', 'MySQL', 'Microsoft SQL', 'Reddis'],
  },
];

export const NAVBAR_LINKS = [
  {
    url: '/',
    text: 'Home',
  },
  {
    url: '/personal-projects',
    text: 'Personal Projects',
  },
  {
    url: '/bots',
    text: 'Discord Bots',
  },
];

const covidbotMD = `
## Covid bot
A discord bot that returns recent coronavirus data from [worldometers.com](https://www.worldometers.info/coronavirus/)

[Click here to add to your server!](https://discordapp.com/oauth2/authorize?client_id=697553145139560588&permissions=0&scope=bot)

🚧 **Bot has been shut down** 🚧

### Commands
\`!covid\` Returns world covid stats

\`!covid <country>\` Returns covid stats regarding that country
`;

const demonslayerbotMD = `
## Demon Slayer bot

A discord bot that turns Discord into an RPG in the Demon Slayer universe!
Join the Demon Slayer Corps, choose a breathing style and  hunt demons down to increase your experience, raise through the ranks and make some money!

Ranks are reset every couple months and special rewards will be given to those who reach the rank of Hashira!

🚧 **Unreleased** 🚧

### Commands
🚧 **Unreleased** 🚧
`;

export const BOTS = [
  {
    name: 'Covid Bot',
    pic: 'https://i.imgur.com/sBVfA89.jpg',
    id: 'covidbot',
    info: covidbotMD,
  },
  {
    name: 'DemonSlayer Bot',
    pic: 'https://i.imgur.com/Ca50lpa.png',
    id: 'demonslayerbot',
    info: demonslayerbotMD,
  },
];

const collaborativeAppDescription = `
**Collaborate Seamlessly with Our Web App**  
Our app makes it effortless to manage and share to-do lists. Here's what you can do:  

- **Create your own to-do lists** for anything — tasks, shopping, or planning.  
- **Share with an unlimited number of collaborators**—friends, family, or teammates.  
- **Enjoy real-time collaboration** to see updates instantly.  
- **No login required**—just create, share, and get started.  
- **Be part of multiple lists at once** for ultimate flexibility.  
- **Anyone with the link can access and edit**, making collaboration hassle-free.  
- **User-friendly interface** that’s simple and intuitive.  

Perfect for managing tasks, shopping lists, organizing group projects, planning events, coordinating team efforts, or even tracking shared goals—whether it’s for work, home, or fun!
`;

const bingeMyAnimeDescription = `
**Binge My Anime!™**  

Binge My Anime!™ is a web app designed to solve a common problem: *“I want to binge the best, most recent anime or catch up on last year’s hidden gems without missing out.”*  

We streamline the journey from discovery to enjoyment by offering:  
- **Top anime at a glance**—quickly find the best and most popular titles.  
- **Trailers and synopses**—get a feel for each anime before diving in.  
- **Links to all available streaming platforms**—start watching instantly.  

No hassle, no delays—just fast and seamless anime discovery so you can focus on what matters: enjoying your next binge-worthy show.  
`;

export const PROJECTS = [
  {
    link: 'https://todo-list-interactive.netlify.app/',
    name: 'Collaborative To-do list',
    description: collaborativeAppDescription,
    id: 'collaborative-todo-list',
    poster: 'https://i.imgur.com/pRbyOtv.png',
    images: [
      'https://i.imgur.com/KPPprI5.png',
      'https://i.imgur.com/pRbyOtv.png',
    ],
  },
  {
    link: 'https://bingemyanime.moe/',
    name: 'BingeMyAnime.moe',
    description: bingeMyAnimeDescription,
    id: 'binge-my-anime',
    poster: 'https://i.imgur.com/IMyOf9O.jpeg',
    images: [
      'https://i.imgur.com/IMyOf9O.jpeg',
      'https://i.imgur.com/jlqI22O.png',
    ],
  },
];
